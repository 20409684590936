import { IconBrandFacebook, IconBrandInstagram, IconMenu2, IconPhone } from "@tabler/icons-react";
import { useState, useEffect } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
export default function Menu() {
  const [showMenu, setShowMenu] = useState(false);
  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const [activeSection, setActiveSection] = useState(null);

  const handleScroll = () => {
    const section1 = document.getElementById("home").getBoundingClientRect();
    const section2 = document.getElementById("oferta").getBoundingClientRect();
    const section3 = document
      .getElementById("standard")
      .getBoundingClientRect();
    const section4 = document
      .getElementById("oInwestycji")
      .getBoundingClientRect();
    const section5 = document.getElementById("oze").getBoundingClientRect();
    const section6 = document
      .getElementById("lokalizacja")
      .getBoundingClientRect();
    const section7 = document.getElementById("kontakt").getBoundingClientRect();
    // ... sprawdź inne sekcje

    if (section1.top <= 0 && section1.bottom >= 0) {
      setActiveSection("section1");
    } else if (section2.top <= 0 && section2.bottom >= 0) {
      setActiveSection("section2");
    } else if (section3.top <= 0 && section3.bottom >= 0) {
      setActiveSection("section3");
    } else if (section4.top <= 0 && section4.bottom >= 0) {
      setActiveSection("section4");
    } else if (section5.top <= 0 && section5.bottom >= 0) {
      setActiveSection("section5");
    } else if (section6.top <= 0 && section6.bottom >= 0) {
      setActiveSection("section6");
    } else if (section7.top <= 0 && section7.bottom >= 0) {
      setActiveSection("section7");
    }

    // ... aktualizuj dla innych sekcji
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <nav className="fixed top-0 left-0 right-0 z-50 hidden p-2 m-5 md:block">
        <div className="container mx-auto">
          <div className="flex items-center justify-between text-gray-800 border-b-4 rounded-lg shadow border-b-primary bg-stone-100">
            <div className="w-auto p-5">
              <LazyLoadImage
              
                src="/images/logo-sloneczne.png"
                alt="Logo - osiedle słoneczne w pobiedziskach"
                width={180}
            
                effect="blur"  
              />
            </div>
            <div className="flex items-center justify-end text-sm me-3">
              <a
                href="/#home"
                className={
                  activeSection === "section1"
                    ? "bg-green-700 rounded text-white  px-5 py-3"
                    : "px-5 py-3 mx-2 my-3 rounded hover:bg-green-700 hover:text-white"
                }
              >
                HOME
              </a>
              <a
                href="/#oferta"
                className={
                  activeSection === "section2"
                    ? "bg-green-700 rounded text-white px-5 py-3"
                    : "px-5 py-3 mx-2 my-3 rounded hover:bg-green-700 hover:text-white"
                }
              >
                DOMY
              </a>
              <a
                href="/#standard"
                className={
                  activeSection === "section3"
                    ? "bg-green-700 rounded text-white px-5 py-3"
                    : "px-5 py-3 mx-2 my-3 rounded hover:bg-green-700 hover:text-white"
                }
              >
                STANDARD
              </a>
              <a
                href="/#oInwestycji"
                className={
                  activeSection === "section4"
                    ? "bg-green-700 rounded text-white px-5 py-3"
                    : "px-5 py-3 mx-2 my-3 rounded hover:bg-green-700 hover:text-white"
                }
              >
                O INWESTYCJI
              </a>
              <a
                href="/#oze "
                className={
                  activeSection === "section5"
                    ? "bg-green-700 rounded text-white px-5 py-3"
                    : "px-5 py-3 mx-2 my-3 rounded hover:bg-green-700 hover:text-white"
                }
              >
                OZE
              </a>
              <a
                href="/#lokalizacja"
                className={
                  activeSection === "section6"
                    ? "bg-green-700 rounded text-white px-5 py-3"
                    : "px-5 py-3 mx-2 my-3 rounded hover:bg-green-700 hover:text-white"
                }
              >
                LOKALIZACJA
              </a>
              <a
                href="/#kontakt"
                className={
                  activeSection === "section7"
                    ? "bg-green-700 rounded text-white px-5 py-3"
                    : "px-5 py-3 mx-2 my-3 rounded hover:bg-green-700 hover:text-white"
                }
              >
                KONTAKT
              </a>
              <a
                href="tel://571458500"
                className="p-2 rounded text-stone-600 focus:outline-none bg-stone-200 me-2"
              >
              <IconBrandInstagram size={28} stroke={1.5} />
              </a>
              <a
                href="tel://571458500"
                className="p-2 rounded text-stone-600 focus:outline-none bg-stone-200 me-2"
              >
              <IconBrandFacebook size={28} stroke={1.5} />
              </a>
            </div>
          </div>
        </div>
      </nav>

      <nav className="fixed top-0 left-0 z-50 block w-full p-5 bg-white border-b-4 rounded-b-lg shadow-md md:hidden border-b-primary ">
        <div className="flex items-center justify-between">
          <div className="w-auto">
            <LazyLoadImage
              src="/images/logo-sloneczne.png"
              alt="Logo - osiedle słoneczne w pobiedziskach"
              effect="blur"
              //layout="fill"
              //objectFit="contain"
              height={60}
              width={180}
            />
          </div>
          <div className="flex items-center justify-end">
            <a
              href="tel://571458500"
              className="p-2 text-white rounded focus:outline-none bg-primary me-2"
            >
              <IconPhone size={30} stroke={1.5} />
            </a>
            <button
              onClick={toggleMenu}
              className="flex items-center justify-end p-2 rounded focus:outline-none bg-stone-200"
            >
              <IconMenu2 size={30} /> <span className="ms-2">MENU</span>
            </button>
          </div>
        </div>
        {showMenu && (
          <div className="mt-5">
            <ul>
              <li>
                <a
                  href="/"
                  onClick={toggleMenu}
                  className="block px-5 py-3 rounded hover:bg-green-700 hover:text-white"
                >
                  HOME
                </a>
              </li>
              <li>
                <a
                  href="/#oferta"
                  onClick={toggleMenu}
                  className="block px-5 py-3 rounded hover:bg-green-700 hover:text-white"
                >
                  OFERTA DOMÓW
                </a>
              </li>
              <li>
                <a
                  href="/#standard"
                  onClick={toggleMenu}
                  className="block px-5 py-3 rounded hover:bg-green-700 hover:text-white"
                >
                  STANDARD WYKONANIA
                </a>
              </li>
              <li>
                <a
                  href="/#oInwestycji"
                  onClick={toggleMenu}
                  className="block px-5 py-3 rounded hover:bg-green-700 hover:text-white"
                >
                  O INWESTYCJI
                </a>
              </li>
              <li>
                <a
                  href="/#oze"
                  onClick={toggleMenu}
                  className="block px-5 py-3 rounded hover:bg-green-700 hover:text-white"
                >
                  ODNAWIALNE ŹRÓDŁA ENERGII
                </a>
              </li>
              <li>
                <a
                  href="/#lokalizacja"
                  onClick={toggleMenu}
                  className="block px-5 py-3 rounded hover:bg-green-700 hover:text-white"
                >
                  LOKALIZACJA
                </a>
              </li>
              <li>
                <a
                  href="/#kontakt"
                  onClick={toggleMenu}
                  className="block px-5 py-3 rounded hover:bg-green-700 hover:text-white"
                >
                  KONTAKT
                </a>
              </li>
            </ul>
          </div>
        )}
      </nav>
    </>
  );
}
