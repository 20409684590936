import {
  IconChevronDown,
  IconChevronUp,
  IconFileTypePdf,
} from "@tabler/icons-react";
import { MobileOnlyView, MobileView, isMobile } from "react-device-detect";
import { useState, useEffect } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import { LazyLoadImage } from 'react-lazy-load-image-component';
export default function ListHouse() {
  const  [nameForm, setNameForm] = useState("");
  const  [emailForm, setEmailForm] = useState("");
  const  [phoneForm, setPhoneForm] = useState("");
  const  [messageForm, setMessageForm] = useState("");
  const [numberForm, setNumberForm] = useState("");
  

  const etap1 = [
    {
      numer: "POS-1/A",
      pokoje: "4",
      powierzchnia: "123.5",
      dzialka: "315",
      cena: "6690",
      promo: "6690",
      status: "Rezerwacja",
      rzut_parter: "/images/rzuty/parter_1L.png",
      rzut_pietro: "/images/rzuty/pietro_1L.png",
      karta: "/doc/1A.pdf",
    },
    {
      numer: "POS-1/B",
      pokoje: "4",
      powierzchnia: "123.5",
      dzialka: "215",
      cena: "6490",
      promo: "6490",
      status: "Dostępny",
      rzut_parter: "/images/rzuty/parter_1R.png",
      rzut_pietro: "/images/rzuty/pietro_1R.png",
      karta: "/doc/1B.pdf",
    },
    {
      numer: "POS-2/A",
      pokoje: "4",
      powierzchnia: "123.5",
      dzialka: "215",
      cena: "6490",
      promo: "6490",
      status: "Rezerwacja",
      rzut_parter: "/images/rzuty/parter_1L.png",
      rzut_pietro: "/images/rzuty/pietro_1L.png",
      karta: "/doc/2A.pdf",
    },
    {
      numer: "POS-2/B",
      pokoje: "4",
      powierzchnia: "123.5",
      dzialka: "315",
      cena: "6690",
      promo: "6690",
      status: "Dostępny",
      rzut_parter: "/images/rzuty/parter_1R.png",
      rzut_pietro: "/images/rzuty/pietro_1R.png",
      karta: "/doc/2B.pdf",
    },
  ];
  const [more, setMore] = useState(isMobile ? "" : "all");
  const [currentFloor, setCurrentFloor] = useState(
    Array(etap1.length).fill("parter")
  );

  // Funkcja do zmiany wyświetlanego rzutu
  const toggleFloor = (index, floor) => {
    setCurrentFloor((currentFloors) =>
      currentFloors.map((f, i) => (i === index ? floor : f))
    );
  };
  function formatCurrency(number) {
    return number.toLocaleString("pl-PL");
  }
  useEffect(() => {
    if (!isMobile) {
      setMore("all");
    }
  }, []);
  const [open, setOpen] = useState(false);


  const onCloseModal = () => setOpen(false);

  const sendFormHandle = (number) => {
    setNumberForm(number);
    setOpen(true);
  }
  return (
    <>
      <section className="relative p-0 pb-5 text-gray-600 bg-stone-100 md:p-4">
        <div className="container mx-auto">
          <div className="p-6 text-start">
            <h2 className="font-bold text-primary text-md">LISTA</h2>
            <h3 className="text-2xl font-light ">Nieruchomości</h3>
          </div>
          <div className="grid grid-cols-1 gap-2 px-3 md:grid-cols-4">
            {etap1.map((item, index) => (
              <div className="flex flex-col bg-white rounded-md" key={index}>
                <div className="flex flex-row items-center justify-start h-16 px-4 py-1 bg-white rounded-md ">
                  <div className="flex flex-col me-3">
                    {/* <div className="p-0 mb-0 text-xs font-light text-gray-400">NR</div> */}
                    <div className="text-sm font-bold">{item.numer}</div>
                  </div>

                  <div className="flex-1 text-xs font-light text-left text-gray-400">
                    {item.status === "Dostępny" ? (
                      <span className="px-3 py-2 font-bold uppercase bg-secondary text-primary">
                        {item.status}
                      </span>
                    ) : (
                      <span className="px-3 py-2 font-bold uppercase text-orangePrimary bg-orangeSecondary">
                        {item.status}
                      </span>
                    )}
                  </div>
        
                  <MobileView>
                    <div className="flex justify-end text-end">
                      {more !== index ? (
                        <button
                          onClick={() => setMore(index)}
                          className="pt-4 pb-4 ps-4"
                        >
                          <IconChevronDown size={24} />
                        </button>
                      ) : (
                        <button
                          onClick={() => setMore("")}
                          className="pt-4 pb-4 ps-4"
                        >
                          <IconChevronUp size={24} />
                        </button>
                      )}
                      {/* <button className="px-4 py-2 text-xs font-bold text-white bg-primary">ZOBACZ WIĘCEJ</button> */}
                    </div>
                  </MobileView>
                </div>
                {more === index || more === "all" ? (
                  <>
                    <div className="w-full px-4 bg-white rounded-md ">
                      <button
                        className={`inline-block px-3 py-2 mb-2 text-sm ${
                          currentFloor[index] === "parter"
                            ? "bg-stone-200"
                            : "bg-stone-100"
                        } me-1`}
                        onClick={() => toggleFloor(index, "parter")}
                      >
                        Rzut parter
                      </button>
                      <button
                        className={`inline-block px-3 py-2 mb-2 text-sm ${
                          currentFloor[index] === "pietro"
                            ? "bg-stone-200"
                            : "bg-stone-100"
                        } me-1`}
                        onClick={() => toggleFloor(index, "pietro")}
                      >
                        Rzut piętro
                      </button>

                      {currentFloor[index] === "parter" && (
                        <Zoom>
                        <LazyLoadImage
                          width={500}
                          height={500}
                          layout="responsive"
                          src={item.rzut_parter}
                          alt="Rzut parteru - domy na sprzedaż"
                          effect="blur"
                        /></Zoom>
                      )}
                      {currentFloor[index] === "pietro" && (
                        <Zoom>
                        <LazyLoadImage
                          width={500}
                          height={500}
                          layout="responsive"
                          src={item.rzut_pietro}
                          alt="Rzut piętra - domy na sprzedaż"
                          effect="blur"
                        /></Zoom>
                      )}
                      <table className="w-full mt-2 text-sm font-light text-gray-600 table-more">
                        <tbody>
                          <tr className="">
                            <td>Powierzchnia domu:</td>
                            <td className="font-semibold text-right ">
                              {item.powierzchnia} m<sup>2</sup>
                            </td>
                          </tr>
                          <tr>
                            <td>Powierzchnia działki:</td>
                            <td className="font-semibold text-right">
                              {item.dzialka} m<sup>2</sup>
                            </td>
                          </tr>
                          <tr>
                            <td>Ilość pokoi:</td>
                            <td className="font-semibold text-right">
                              {item.pokoje}
                            </td>
                          </tr>
                          <tr>
                            <td>Miejsca parkingowe:</td>
                            <td className="font-semibold text-right">2</td>
                          </tr>
                          <tr>
                            <td>Cena:</td>
                            <td className="font-semibold text-right ">
                              {item.status === "Dostępny" ? (
                                <>
                                  {" "}
                                  {formatCurrency(item.cena)} zł/m<sup>2</sup>{" "}
                                </>
                              ) : (
                                <>-</>
                              )}
                            </td>
                          </tr>

                          <tr>
                            <td>Cena całkowita:</td>
                            <td className="font-semibold text-right">
                              {item.status === "Dostępny" ? (
                                <>
                                  {formatCurrency(
                                    item.promo * item.powierzchnia
                                  )}{" "}
                                  zł
                                </>
                              ) : (
                                <>-</>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td
                              colspan="2"
                              className="text-xs text-gray-400 border-none"
                            >
                              *cena brutto
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div className="flex flex-row justify-between mt-1 mb-3">
                        <Modal
                          open={open}
                          onClose={onCloseModal}
                          center
                          classNames={"w-96"}
                        >
                          <h2>Formularz kontaktowy</h2>
                          <div className="flex flex-col w-80 md:w-96">
                            <p className="py-3 text-xs font-thin">
                              Jeżeli jesteś zainteresowany kupnem domu, wypełnij
                              poniższy formularz. Wkrótce skontaktujemy się z
                              Tobą i przedstawimy ofertę.
                            </p>
                            <div>
                              <label className="block mb-2 text-xs font-semibold text-stone-600">Imię i nazwisko:</label>
                              <input type="text" onChange={(e) => setNameForm(e.target.value)} value={nameForm}  className="block w-full h-10 px-3 py-2 mb-2 text-sm border bg-stone-100" placeholder="Imię i nazwisko" />
                            </div>
                            <div>
                              <label className="block mb-2 text-xs font-semibold text-stone-600">Adres email:</label>
                              <input type="text" onChange={(e) => setEmailForm(e.target.value)} value={emailForm} className="block w-full h-10 px-3 py-2 mb-2 text-sm border bg-stone-100" placeholder="Twój adres email" />
                            </div>
                            <div>
                              <label className="block mb-2 text-xs font-semibold text-stone-600">Telefon:</label>
                              <input type="text" onChange={(e) => setPhoneForm(e.target.value)} value={phoneForm} className="block w-full h-10 px-3 py-2 mb-2 text-sm border bg-stone-100" placeholder="Twój numer telefonu" />
                            </div>
                            <div>
                              <label className="block mb-2 text-xs font-semibold text-stone-600">Wiadomość:</label>
                              <textarea onChange={(e) => setMessageForm(e.target.value)} value={messageForm} rows="5" className="block w-full px-3 py-2 mb-2 text-sm border bg-stone-100" placeholder="Treść wiadomości" />
                            </div>
                            <div className="flex flex-row justify-bettwen">
                            <button className="w-48 px-4 py-4 text-xs font-semibold text-stone-600 bg-stone-200">Anuluj</button>
                            <button className="w-48 px-4 py-4 text-xs font-semibold text-white bg-primary">Wyślij wiadomość</button>
                            </div>
                          </div>
                        </Modal>
                        <button
                          className="w-48 px-4 py-4 text-xs font-bold bg-stone-200"
                          onClick={() => sendFormHandle(item.numer)}
                        >
                          Zadaj pytanie
                        </button>
                        <a
                          href={item.karta}
                          download={item.karta}
                          className="flex flex-row items-center justify-center w-full px-4 py-2 text-xs font-bold text-white bg-primary"
                        >
                          <IconFileTypePdf
                            size={24}
                            stroke={1.5}
                            className="me-3"
                          />{" "}
                          KARTA LOKALU
                        </a>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            ))}
          </div>
          <div className="mb-5"></div>
        </div>
      </section>
    </>
  );
}
