import { IconBrandFacebook, IconBrandInstagram, IconPhone } from "@tabler/icons-react";

export default function Fotter() {
  return (
    <section id="kontakt" className="p-0 text-gray-600 section md:p-4">
      <div className="container relative mx-auto bg-white">
        <div className="p-6 text-start">
          <h2 className="font-bold text-primary text-md">KONTAKT</h2>
          <h3 className="text-2xl font-light ">Skontaktuj się z nami</h3>
          </div>
        <div className="grid grid-cols-1 md:grid-cols-3">
         
            <div className="p-6 pt-0 text-start">
              
              <div className="">
                <h3 className="text-4xl text-primary">
                  <a
                    href="tel://571458500"
                    className="flex flex-row items-center"
                  >
                    <IconPhone size={48} stroke={1} className="rotate-45 m" />{" "}
                    571 458 500
                  </a>
                </h3>
              </div>
              <div className=" text-start">
              <p className="pt-6 pb-3 text-xs">
                Jesteśmy do Twojej dyspozycji i chętnie odpowiemy na wszystkie
                pytania.
              </p>
            </div>
            </div>
            
            
            
            <div className="p-6 pt-0">
              <h6 className="mb-2 text-sm font-bold text-primary">
                ECO HOME POLAND PROSTA SPÓŁKA AKCYJNA
              </h6>
              <address className="mb-2 text-sm"> ul. Św. Marcin 43/1, 61-806 Poznań</address>
              <p className="text-sm">
                NIP: 9721328852 | Kapitał Akcyjny: 500 000,00 zł
              </p>
            </div>
            <div className="p-6 pt-0">
            <div className="pb-4 text-xs">Znajdziesz nas również na:</div>
            <a
                href="tel://571458500"
                className="inline-block p-2 rounded text-stone-600 focus:outline-none bg-stone-200 me-2"
              >
              <IconBrandInstagram size={28} stroke={1.5} />
              </a>
              <a
                href="tel://571458500"
                className="inline-block p-2 rounded text-stone-600 focus:outline-none bg-stone-200 me-2"
              >
              <IconBrandFacebook size={28} stroke={1.5} />
              </a>
              </div>
          
        </div>
      </div>
    </section>
  );
}
