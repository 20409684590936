import { IconChevronDown } from "@tabler/icons-react";
import React, { useState } from "react";
import { BrowserView, MobileView } from 'react-device-detect';
export default function OZE({ oze }) {
  const [more, setMore] = useState("");
  return (
    <section id="oze" className="p-0 text-gray-600 bg-primary "
    style={{backgroundImage: "url('/images/pompa_ciepla_oze.png')", backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundPosition: "right"}}
    >
      <div className="container relative mx-auto">
        <div className=" text-start">
          <div className="flex flex-row items-center justify-start ">
          <div className="w-full p-6 md:p-10 md:3/5 bg-primary">
          <h2 className="font-bold text-white text-md">
            ENERGOOSZCZĘDNE ROZWIĄZANIA
          </h2>
          <h3 className="text-3xl font-light text-white ">
            Odnawialne źródła energii
          </h3>
          <p className="pt-6 pb-3 text-sm text-justify text-white">
            Odnawialne Źródła Energii (OZE) to kluczowy temat, który odgrywa
            istotną rolę w naszych innowacyjnych i ekologicznych projektach
            budowlanych. OZE to pojęcie oznaczające zrównoważone i niezależne od
            paliw kopalnych źródła energii, które są odnawiane naturalnie w
            krótkim czasie i nie wykorzystują się ich na stałe. W naszych domach
            wykorzystujemy trzy główne technologie z zakresu OZE, które
            przyczyniają się zarówno do ochrony środowiska, jak i zmniejszenia
            kosztów eksploatacji budynków:
          </p>
          <MobileView>
          <div className="grid grid-cols-1 md:grid-cols-3">
            {oze.map((item, index) => (
              <div className="" key={index}>
                <div className="flex flex-row items-center justify-start p-2 ">
                  <button
                    onClick={() => setMore(index)}
                    className="flex flex-row justify-between font-bold text-white"
                  >
                    {item.title} <IconChevronDown size={24} />
                  </button>
                </div>
                {more === index && (
                  <div className="ps-3">
                    <p className="mt-3 mb-3 text-sm text-gray-100 border-l border-white ps-3 ">
                      {item.content}
                    </p>
                  </div>
                )}
              </div>
            ))}
          </div>
          </MobileView>
          <BrowserView>
          <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
            {oze.map((item, index) => (
              <div className="" key={index}>
                <div className="flex flex-row items-center justify-start p-2 ">
                  <div
                    
                    className="flex flex-row justify-between font-bold text-white"
                  >
                    {item.title} 
                  </div>
                </div>
               
                  <div className="ps-3">
                    <p className="mt-3 mb-3 text-sm text-justify text-white border-l border-white ps-3 ">
                      {item.content}
                    </p>
                  </div>
               
              </div>
            ))}
          </div>
          </BrowserView>
          <p className="pt-6 pb-3 text-sm text-white">
            Nie tylko chronimy środowisko, redukując emisję gazów
            cieplarnianych, ale również osiągamy oszczędności energetyczne i
            obniżamy koszty eksploatacji. Jest to nasz wkład w zrównoważoną
            przyszłość, opartą na czystej i odnawialnej energii.
          </p>
        </div>
        <div className="hidden w-2/5 md:block">
        {/* <img src="/images/planet.png" alt="OZE" width="50%" height="100%" /> */}
        </div>
        </div>
        </div>
      </div>
    </section>
  );
}
