export default function HouseSVG(props) {
  return (
    <section
      id="oferta"
      className="p-0 text-gray-600 bg-stone-100 md:p-4"
    >
      <div className="container mx-auto">
        <div className="p-6 pb-0 text-center">
          <h2 className="font-bold text-primary text-md">OFERTA</h2>
          <h3 className="text-2xl font-thin ">Domów energooszczędnych</h3>
        </div>
        <p className="p-6 text-sm text-center md:px-64">
          Inwestycja Osiedle Słoneczne w Pobiedziskach oferuje domy
          energooszczędne. Budowa domów została podzielona na dwa etapy.
          Aktualnie w sprzedaży oferujemy Państwu domy z etapu I którego
          zakończenie prac planujemy pod koniec{" "}
          <span className="font-bold text-primary">I kwartału 2024.</span>
        </p>
        <div className="">
  

          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
           
            x="0px"
            y="0px"
            viewBox="0 0 1600 764"
            style={{ enableBackground: "new 0 0 1600 764" }}
         
          >
      
            <g id="Warstwa_2">
              <image
                style={{ overflow: "visible" }}
                width="1600"
                height="764"
                id="Bitmap"
                href="/images/rzut_nowy.webp"
              ></image>
              <polygon
                id="Polygon"
                className="st2 polygon"
           
                points="274.7,346.3 298.3,346 298.8,349.7 396.9,349.7 283.9,416.2 301.1,506.3 317.2,506.3 
		322,541 310.7,552.2 311,558.8 271.3,558.9 263.3,565.9 164.4,565.9 152,517.6 128.9,517.6 103.6,424.5 270.3,351.1 270.3,348.8 	
		"
              />
              <polygon
                id="Polygon_00000116234480205045787720000003804939068102865028_"
                class="st2 polygon"
                points="517.1,349.7 520.3,349.7 523,346 
		545.7,346 545.7,349.7 631.8,349.7 589.7,415.2 595.3,505.7 602.2,506 603.8,545.9 601.2,550.7 601.2,558.9 560.6,558.9 
		556.7,565.9 456.3,565.9 449.6,517.2 439.9,517.2 424.6,424.5 	"
              />
              <polygon
                id="Polygon_00000183939783837338076430000000641180462196812697_"
                class="st1 polygon"
                points="396.9,349.7 471.9,349.7 
		474.5,346 498.8,346 498.8,349.7 517.1,349.7 427,424.2 439.9,517.2 449.6,517.2 456.3,565.9 357.9,565.9 357.9,558.9 311,558.8 
		310.7,552.2 322,541 317.2,506.3 301.1,506.3 283.9,416.2 	"
              />
              <polygon
                id="Polygon_00000161630532289418116860000017732843501121755327_"
                class="st1 polygon"
                points="631.8,349.7 721.8,349.7 
		722.5,346 746.2,346 746.2,349.7 760.1,349.7 760.1,447.9 754.5,518.2 750.9,559.9 750.9,565.9 647.6,565.9 647.6,558.9 
		602.4,558.9 601.2,550.7 603.8,545.9 602.4,505.7 595.3,505.7 589.7,415.2 	"
              />
              <polygon
                id="Polygon_00000120537252107185859020000005984522696442763398_"
                class="st0 polygon"
                points="1010.9,349.7 1084.1,349.7 
		1084.9,344.4 1106.6,344.4 1105.1,342.2 1130.9,342.2 1130.9,336.6 1156,336.6 1156.6,341.4 1161.1,342.2 1161.1,336.6 
		1284.8,336.6 1336.8,358.2 1336.8,365.9 1342.4,370.6 1364.7,370.6 1440.3,404.2 1424.3,465.6 1519.7,516.7 1517.1,533.2 
		1532.6,542.8 1508.4,623.2 1312.6,623.2 1252.7,563.4 1081.4,563.4 1081.4,559.2 996.2,559.2 996.2,563.4 891.2,563.4 868.3,457.8 
		872.8,349.7 886.2,349.7 886.2,345.7 908.3,345.7 910.4,349.7 	"
              />
            </g>
          </svg>
        </div>
      </div>
    </section>
  );
}
