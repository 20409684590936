
import { MobileOnlyView } from "react-device-detect";
import ScrollCarousel from "./ScrollCarouselComponent";
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function AboutInvest() {
  return (
    <section
      id="oInwestycji"
      className="p-0 text-gray-600 bg-stone-100 " 
      style={{backgroundImage: "url('/images/foto_o.webp')", backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundPosition: "left"}}
    >
      <MobileOnlyView>
      <LazyLoadImage effect="blur" src="/images/foto_o.webp" alt="O inwestycji - domy na sprzedaż" width="100%" height="auto" />
      </MobileOnlyView>
      <div className="container relative mx-auto">
      <div className="flex flex-row">
      <div className="items-end justify-end hidden w-2/5 md:flex">
        <LazyLoadImage
          src="/images/sygnet_zielony2.png"
          alt="Domy w pobiedziskach na sprzedaż"
          width="130"
          height="130"
          effect="blur"
          className="opacity-60 me-16"
          />
      </div>
        <div className="w-full md:w-3/5">
        <div className="bg-stone-100">
        <div className="p-6 md:pt-12 text-start">
          <h2 className="font-bold text-primary text-md">O INWESTYCJI</h2>
          <h3 className="text-3xl font-light ">Osiedle Słoneczne</h3>
        </div>
        <div className="block md:hidden">
       
        </div>
        <div className="p-6 pt-6 md:pt-0 text-start">
          <p className="pb-3 text-sm text-justify">
            Osiedle Słoneczne - wyjątkowa propozycja inwestycji{" "}
            <a
              href="https://ecohomepoland.pl"
              className="font-bold text-primary"
            >
              Eco Home Poland P.S.A.
            </a>{" "}
            Oferujemy domy w zabudowie bliźniaczej dwulokalowej, doskonale
            usytuowane w podpoznańskich Pobiedziskach.
          </p>
          <p className="pb-3 text-sm text-justify">
            Nasze budynki zostały zaprojektowane w nowoczesnej stylistyce,
            zgodnej z najnowszymi trendami w architekturze. Priorytetem dla
            naszego zespołu projektantów było zapewnienie jak największego
            komfortu przyszłym mieszkańcom. Prosta bryła budynków skrywa ciekawą
            powierzchnię użytkową, która idealnie odpowiada potrzebom
            nowoczesnej rodziny. Wyraźne podziały wnętrza na strefy gwarantują
            maksymalną wygodę użytkowania domu.
          </p>
          <p className="pb-3 text-sm text-justify">
            Wyróżniamy się ponadprzeciętną wielkością pomieszczeń oraz
            zastosowaniem{" "}
            <span class="text-primary font-bold">materiałów klasy premium</span>
            . Dzięki nowoczesnej technologii, nasze domy charakteryzują się
            doskonałym parametrem przenikania ciepła U=0,17. To oznacza, że
            zapewniamy doskonałą
            <span className="font-bold text-primary"> izolację termiczną</span>.
            Co więcej, świadomi odpowiedzialności za środowisko, korzystamy
            również z{" "}
            <span className="font-bold text-primary">
              Odnawialnych Źródeł Energii
            </span>
            . Dzięki temu nasze domy są przyjazne dla środowiska i
            energooszczędne.
          </p>
          <p className="pb-3 text-sm text-justify">
            To tylko niektóre z wielu atutów, które wyróżniają markę Osiedle
            Słoneczne spośród innych inwestycji deweloperskich. Jeśli szukasz
            domu, który spełni wszystkie Twoje oczekiwania pod względem
            <span className="font-bold text-primary">
              {" "}
              komfortu, jakości i enegooszczędności
            </span>
            , to Osiedle Słoneczne jest idealnym wyborem dla Ciebie.
          </p>
          <p className="text-sm pb-9">
            Zapraszamy do zapoznania się z naszą ofertą i dołączenia do grona
            zadowolonych mieszkańców Osiedla Słonecznego.
          </p>
        </div></div></div>
      </div></div>
    </section>
  );
}
