import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import { LazyLoadImage } from 'react-lazy-load-image-component';

export default function HeroSliderView() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  return (
    <>
      <div className="mt-24 md:mt-0 bg-stone-100">
        <Slider {...settings}>
          <div className="relative w-full h-80  md:h-[650px]">
            <div className="container relative z-50 h-full mx-auto ">
              <div className="absolute z-50 top-5 md:top-48 left-3 md:left-14">
                <h5 className="inline-block px-2 py-1 mb-1 text-sm font-semibold text-white md:py-3 md:px-4 md:block md:text-3xl bg-primary opacity-80">
                  Domy energooszczędne w klasie
                  <span className="font-thin">
                    {" "}
                    <i>premium</i>
                  </span>
                </h5>
                <div className="flex flex-col items-start justify-start">
                  <h6 className="inline-block px-2 py-1 mb-1 text-xs font-light bg-white md:text-base md:py-2 md:px-3 opacity-80">
                    Kameralne osiedle <b>8 domów, </b> Pow. domu{" "}
                    <b>
                      123,5 m<sup>2</sup>{" "}
                    </b>
                  </h6>
                </div>
              </div>
              <div className="absolute z-50 bottom-5 md:bottom-10 left-3 md:left-14">
                <h6 className="inline-block px-2 py-1 text-xs font-light text-stone-800 md:text-base md:py-3 md:px-3 bg-orangePrimary opacity-80">
                  <span className="text-xs font-medium">
                    Teraz w  cenie:{" "}
                  </span>
                  <br />
                  <span className="text-base md:text-3xl">od </span>
                  <span className="text-base font-semibold md:text-3xl">
                   6500,00
                  </span>
                  <span className="text-base font-normal ">
                    {" "}
                    zł/m<sup>2</sup>
                  </span>
                </h6>
                <img
                  src="/images/r.png"
                  effect="blur"
                  alt="Oferta deweloperska domy Pobiedziska"
                  className="w-3 h-3 md:w-7 md:h-7 opacity-80"
                />
              </div>
            </div>
            <div className="absolute inset-0 z-0">
              <LazyLoadImage
                src="/images/r1_hero1.webp"
                width="1600"
                height="650"
                effect="blur"
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
                alt="oferta deweloperska domy Pobiedziska na sprzedaż"
              />
            </div>
          </div>
          <div className="relative w-full h-80 md:h-[650px]">
            <div className="container relative z-50 h-full mx-auto ">
              <div className="absolute z-50 top-5 md:top-48 left-3 md:left-14">
                <h5 className="inline-block w-2/3 px-2 py-1 mb-1 text-sm text-white md:w-full md:py-3 md:px-4 md:block md:text-2xl bg-primary opacity-80">
                Każdy dom wyposażony  
                  <span className="font-medium"> w pompę ciepłą, rekuperacje i
                    instalację PV</span>
                </h5>
                <div className="flex flex-col items-start justify-start">
                  
                  <h6 className="inline-block px-2 py-1 mb-1 text-xs font-light bg-white md:text-base md:py-2 md:px-3 opacity-80">
                    Pow. domu{" "}
                    <b>
                      123,5 m<sup>2</sup>
                    </b>
                    , Pow. ogrodów od{" "}
                    <b>
                      215 m<sup>2</sup>
                    </b>
                    -{" "}
                    <b>
                      315 m<sup>2</sup>
                    </b>
                  </h6>
                </div>
              </div>
              <div className="absolute z-50 bottom-5 md:bottom-10 left-3 md:left-14">
                <h6 className="inline-block px-2 py-1 text-xs font-light text-stone-800 md:text-base md:py-3 md:px-3 bg-orangePrimary opacity-80">
                  <span className="text-xs font-medium">
                    Teraz w cenie:{" "}
                  </span>
                  <br />
                  <span className="text-base md:text-3xl">od </span>
                  <span className="text-base font-semibold md:text-3xl">
                    6500,00
                  </span>
                  <span className="text-base font-normal ">
                    {" "}
                    zł/m<sup>2</sup>
                  </span>
                </h6>
                <img
                  src="/images/r.png"
                  effect="blur"
                  className="w-3 h-3 md:w-7 md:h-7 opacity-80"
                  alt="Oferta deweloperska domy Pobiedziska"
                />
              </div>
            </div>
            <div className="absolute inset-0 z-0">
              <LazyLoadImage
                src="/images/r1_hero3.webp"
                width="1600"
                height="650"
                effect="blur"
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
                alt="oferta deweloperska domy Pobiedziska na sprzedaż"
              />
            </div>
          </div>
        </Slider>
      </div>
      {/* 
      <div className="block w-full mt-24 h-1/2-screen md:hidden ">
        <Slider {...settings}>
        <div className="relative w-full h-96"  >        
        <div className="container relative z-50 h-full mx-auto " >
              <div className="absolute z-50 top-12 left-14">
                <h5 className="block px-4 py-3 mb-1 text-xl font-bold text-white bg-primary opacity-80">
                  WYJĄTKOWE DOMY{" "}
                  <span className="font-light">W POBIEDZISKACH</span>
                </h5>
                <div className="flex flex-col items-start justify-start">
                <h6 className="inline-block px-3 py-2 mb-1 font-light bg-orangePrimary opacity-80">
                  Każdy dom wyposażony w  pompę ciepłą, rekuperacje i instalację PV
                </h6>
                <h6 className="inline-block px-3 py-2 mb-1 font-light bg-white opacity-80">
                   Pow. domu <b>123,5 m<sup>2</sup></b>, Pow. ogrodów od <b>215 m<sup>2</sup></b>- <b>215 m<sup>2</sup></b>
                </h6>
                
                <h6 className="inline-block px-3 py-1 font-light text-white bg-primary opacity-80">
                  <span className="text-xs font-bold">Teraz w promocyjnej cenie: </span><br />
                  <span className="text-3xl">od </span><span className="text-3xl font-bold">5990,00</span><span className="font-normal"> zł/m<sup>2</sup></span>
                </h6>

                </div>
              </div>
              
            </div>
            <div className="absolute inset-0 z-0">
            <Image
              src="/images/r1_hero1.webp"
              width="1600"
              height="650"
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
              alt="Image 1"
            />
            </div>
          </div>

          <Image
            src="/images/r1_hero1.webp"
            layout="responsive"
            height={500}
            width={1600}
            style={{ objectFit: "cover" }}
            alt="Image 1"
          />
        </Slider>
      </div> */}
    </>
  );
}
