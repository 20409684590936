import "./App.css";

import { IconSquareRoundedChevronsDown } from "@tabler/icons-react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useState, useEffect } from "react";
import HeroSliderView from "./Components/HeroSlider";
import ListHouse from "./Components/ListaHouse";
import Menu from "./Components/Menu";
import HouseSVG from "./Components/HouseSVG";
import AboutInvest from "./Components/AboutInvest";
import OZE from "./Components/OZE";
import Fotter from "./Components/Fotter";
import { ReactComponent as PlayIcon } from "./play.svg";
import ModalVideo from "react-modal-video";
import {
  APIProvider,
  Map,
  Marker,
  AdvancedMarker,
  InfoWindow,
} from "@vis.gl/react-google-maps";
import { BrowserView, MobileView } from "react-device-detect";

const API_KEY = "AIzaSyDlJ2h0a3IpyzV_pZ4Al0tW6mDrMBvFLa8";

const containerStyle = {
  width: "100%",
  height: "600px",
};

const center = {
  lat: 52.48831731942848,
  lng: 17.2963160276413,
};

export default function Home() {
  const info = [
    {
      title: "Domy energooszczędne",
      content:
        "Nasze energooszczędne domy charakteryzują się niezwykle wysoką efektywnością energetyczną i innowacyjnością.",
      icon: "/images/eco_house.svg",
    },
    {
      title: "Kameralne osiedle",
      content:
        " Nasze osiedle składa się z 8 domów, które tworzą kameralne osiedle i zapewniają spokój oraz prywatność.",
      icon: "/images/kameralne.svg",
    },
    {
      title: "Duże zielone ogrody",
      content:
        "Nasze domy posiadają przestronne, pięknie ogrody, które stanowią niepowtarzalne miejsce pełne zieleni i spokoju.",
      icon: "/images/tree.svg",
    },
    {
      title: "Odnawialne źródła energii",
      content:
        "Dzięki wykorzystaniu OZE nasze domy są niezwykle ekonomiczne w utrzymaniu, co przekłada się na niższe koszty eksploatacji.",
      icon: "/images/oze.svg",
    },
  ];

  const standard = [
    {
      nazwa: "POMPA CIEPŁA",
      opis: "Pompy ciepła to najbardziej efektywne źródła dla ogrzewania naszych domów. Dlatego też w naszych inwestycjach to standard.",
      foto: "/images/icon/pompa_ciepla.png",
    },
    {
      nazwa: "INSTALACJA FOTOWOLTAICZNA",
      opis: "Instalacja fotowoltaiczna to niezbędny element naszych projektów, zapewniający ekologiczne źródło energii i długoterminowe oszczędności.",
      foto: "/images/icon/fotowoltaika.png",
    },
    {
      nazwa: "REKUPERACJA",
      opis: "Nasze domy posiadają rekuperację z odzyskiem ciepła, które odzyskują ciepło z wentylowanego powietrza, przyczyniając się do oszczędności.",
      foto: "/images/icon/rekuperacja.png",
    },
    {
      nazwa: "OGRZEWANIE PODŁOGOWE",
      opis: "Dzięki ogrzewaniu wodnym, które równomierne i efektywne rozprowadza ciepło, zapewniając nie tylko komfort termiczny, ale także oszczędności.",
      foto: "/images/icon/podloga.png",
    },
    {
      nazwa: "DUŻE PRZESZKLENIA",
      opis: "To element naszych domów, zapewniający dużą ilość naturalnego światła, ale również tworząc harmonię pomiędzy wnętrzem a otoczeniem.",
      foto: "/images/icon/okna.png",
    },
    {
      nazwa: "ELEKTRYCZNE ROLETY",
      opis: "Nasze domy są wyposażone w elektryczne rolety, zapewniające prywatność oraz ochronę przed nadmiernym nasłonecznieniem.",
      foto: "/images/icon/rolety.png",
    },
    {
      nazwa: "IZOLACJA ŚCIAN I DACHU",
      opis: "Dzięki wykorzystaniu materiałów klasy premium i zaawansowanych rozwiązań konstrukcyjnych, osiągnęliśmy genialny parametr ścian U=0,17 W/m2K!",
      foto: "/images/icon/izolacja.png",
    },
    {
      nazwa: "PŁYTA FUNDAMENTOWA",
      opis: "Dzięki zastosowaniu płyty fundamentowej uzyskujemy doskonałą izolację termiczną, chroniąc dom przed stratami ciepła.",
      foto: "/images/icon/plyty.png",
    },
    {
      nazwa: "FUNKCJONALNY PROJEKT",
      opis: "Projekt domów charakteryzuje się multi funkcjonalnością, uwzględniając optymalne wykorzystanie przestrzeni.",
      foto: "/images/icon/projekt.png",
    },
    {
      nazwa: "ZAMKNIĘTE OSIEDLE",
      opis: "Dzięki ogrodzeniu oraz bramie wjazdowej, zapewniamy naszym mieszkańcom spokój, prywatność i bezpieczeństwo.",
      foto: "/images/icon/ogrodzenie.png",
    },
    {
      nazwa: "2 MIEJSCA PARKINGOWE",
      opis: "Do każdego z domów przynależą 2 prywatne miejsca parkingowe lub garażowe bezpośrednio przed wejściem do domu.",
      foto: "/images/icon/parking.png",
    },
    {
      nazwa: "DUŻE ZIELONE OGRODY",
      opis: "Nasze domy posiadają przestronne, pięknie ogrody, które stanowią niepowtarzalne miejsce pełne zieleni i spokoju.",
      foto: "/images/icon/drzewa.png",
    },
  ];

  const oze = [
    {
      title: "POMPA CIEPŁA",
      content:
        "Nasze domy korzystają z zaawansowanych systemów pomp ciepła, które wykorzystują energię zawartą w powietrzu do efektywnego ogrzewania i chłodzenia pomieszczeń. Dzięki temu zapewniamy komfort termiczny, jednocześnie ograniczając zużycie energii konwencjonalnej.",
    },
    {
      title: "INSTALACJA FOTOWOLTAICZNA",
      content:
        "Nasze domy wyposażone są w panele fotowoltaiczne, które przekształcają energię słoneczną w elektryczność. To oznacza, że możemy produkować własną energię elektryczną z bezpośredniego źródła - Słońca. Nasze panele fotowoltaiczne są strategicznie umieszczone na dachach, aby maksymalnie wykorzystać światło słoneczne i zminimalizować zużycie energii elektrycznej z sieci.",
    },
    {
      title: "REKUPERACJA",
      content:
        "W naszych domach zastosowaliśmy systemy rekuperacji, które pozwalają na odzyskiwanie ciepła z wywiewanego powietrza i wykorzystanie go do podgrzewania świeżego powietrza doprowadzanego do wnętrza. Ta inteligentna technologia minimalizuje straty ciepła i poprawia efektywność energetyczną, redukując jednocześnie koszty eksploatacyjne.",
    },
  ];
  const mapStyle = [
    {
      featureType: "poi.business",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "poi.park",
      elementType: "labels.text",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "all",
      elementType: "labels.text",
      stylers: [
        {
          color: "#878787",
        },
      ],
    },
    {
      featureType: "all",
      elementType: "labels.text.stroke",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "landscape",
      elementType: "all",
      stylers: [
        {
          color: "#f9f5ed",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "all",
      stylers: [
        {
          color: "#f5f5f5",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "geometry.stroke",
      stylers: [
        {
          color: "#c9c9c9",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "all",
      stylers: [
        {
          color: "#aee0f4",
        },
      ],
    },
  ];

  const [isExpanded, setIsExpanded] = useState(false);
  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  const [selectedMarker, setSelectedMarker] = useState(false);
  const handleClick = () => {
    setSelectedMarker(true);
  };

  // Funkcja wywoływana, gdy zamkniesz InfoWindow
  const handleCloseClick = () => {
    setSelectedMarker(null);
  };
  const marker = [
    {
      lat: 52.48804619755764,
      lng: 17.296149730682377,
      icon: "/images/icon/maps/logo.png",
      title: "Osiedle Słoneczne",
      content: "Osiedle Słoneczne",
      dystans: "0 m",
    },
    {
      lat: 52.484867740630655,
      lng: 17.28260457515717,
      icon: "/images/icon/maps/jezioro.png",
      title: "Jezioro",
      content: "Jezioro Biezdruchowo",
      dystans: "950 m",
    },
    {
      lat: 52.47923872773412,
      lng: 17.277980446815494,
      icon: "/images/icon/maps/pociag.png",
      title: "Dworzec PKP",
      content: "Dworzec PKP",
      dystans: "1500 m",
    },
    {
      lat: 52.4797614754606,
      lng: 17.27935910224915,
      icon: "/images/icon/maps/autobus.png",
      title: "Przystanek autobusowy",
      content: "Autobus gminny",
      dystans: "1500 m",
    },
    {
      lat: 52.482166035005775,
      lng: 17.283725738525394,
      icon: "/images/icon/maps/autobus.png",
      title: "Przystanek autobusowy",
      content: "Autobus gminny",
      dystans: "1100 m",
    },
    {
      lat: 52.4791439790438,
      lng: 17.28256165981293,
      icon: "/images/icon/maps/przedszkole.png",
      title: "Przedszkole",
      content: "Przedszkole",
      dystans: "1400 m",
    },
    {
      lat: 52.48293050058809,
      lng: 17.290152311325077,
      icon: "/images/icon/maps/sklep.png",
      title: "Sklep Biedronka",
      content: "Sklep spożywczy",
      dystans: "750 m",
    },
    {
      lat: 52.47940862142649,
      lng: 17.280249595642093,
      icon: "/images/icon/maps/sklep.png",
      title: "Sklep Stokrotka",
      content: "Sklep spożywczy",
      dystans: "1400 m",
    },
    {
      lat: 52.4779514349789,
      lng: 17.279005050659183,
      icon: "/images/icon/maps/sklep.png",
      title: "Sklep Biedronka",
      content: "Sklep spożywczy",
      dystans: "1700 m",
    },
    {
      lat: 52.47500424224108,
      lng: 17.28804409503937,
      icon: "/images/icon/maps/szkola.png",
      title: "Liceum Ogólnokształcące",
      content: "Liceum Ogólnokształcące",
      dystans: "1500 m",
    },
    {
      lat: 52.474226568670744,
      lng: 17.2878509759903,
      icon: "/images/icon/maps/szkola.png",
      title: "Szkoła Podstawowa",
      content: "Szkoła Podstawowa",
      dystans: "1600 m",
    },
    {
      lat: 52.47604329724308,
      lng: 17.29060292243958,
      icon: "/images/icon/maps/jezioro.png",
      title: "Jezioro",
      content: "Jezioro Małe",
      dystans: "1400 m",
    },
    {
      lat: 52.4762491448505,
      lng: 17.282534837722782,
      icon: "/images/icon/maps/medi.png",
      title: "Ośrodek zdrowia",
      content: "Ośrodek zdrowia PRONUS",
      dystans: "2000 m",
    },
    {
      lat: 52.47723916036625,
      lng: 17.287223339080814,
      icon: "/images/icon/maps/rynek.png",
      title: "Rynek w Pobiedziskach",
      content: "Rynek w Pobiedziskach",
      dystans: "1600 m",
    },
  ];
  const [isOpen, setOpen] = useState(false);
  return (
    <main className="min-h-screen " id="home">
      <Menu />
      <HeroSliderView />
      <section className="p-0 text-gray-600 bg-stone-100 bg-gradient-to-tl bgGradient-green3 md:p-4">
        <div className="container relative mx-auto">
          <div className="p-3 md:p-6 text-start">
            <div className="flex flex-col items-end rounded-lg md:flex-row content-baseline justify-baseline ">
              <div className="w-full h-auto md:w-1/3">
                <h2 className="mb-2 text-2xl font-thin text-stone-800">
                  Zobacz film reklamowy
                </h2>
                <div className="relative w-full h-40 mb-2 md:h-60 md:pe-6 md:mb-0">
                  <ModalVideo
                    channel="youtube"
                    youtube={{ mute: 0, autoplay: 1 }}
                    isOpen={isOpen}
                    videoId="fI5z5eSM80c"
                    onClose={() => setOpen(false)}
                  />

                  <img
                    src="/images/video.webp"
                    alt="Video"
                    className="absolute top-0 bottom-0 left-0 right-0"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                  <div className="absolute top-0 bottom-0 left-0 right-0 z-10 bg-gray-600 opacity-20 hover:bg-transparent"></div>
                  <div className="absolute top-0 bottom-0 left-0 right-0 z-20 flex flex-col items-center justify-center">
                    <MobileView>
                      <PlayIcon
                        onClick={() => setOpen(true)}
                        className="w-20 h-20 text-white cursor-pointer"
                      />
                    </MobileView>
                    <BrowserView>
                      <PlayIcon
                        onClick={() => setOpen(true)}
                        className="w-20 h-20 text-white cursor-pointer hover:-w25 hover:h-25 hover:text-primary"
                      />
                    </BrowserView>
                  </div>
                </div>
              </div>

              <div className="h-full rounded-lg md:w-2/3">
                <div className="grid grid-cols-2 gap-2 md:gap-3 md:ms-6 md:grid-cols-4">
                  {info.map((item, index) => (
                    <div
                      className="flex flex-col items-start bg-white md:h-60 justify-top"
                      key={index}
                    >
                      <div className="flex flex-col items-center justify-center p-2 md:p-5 ">
                        <div className="h-[60px] w-[60px] p-4 rounded-full bg-stone-100 block">
                          <img
                            src={item.icon}
                            alt="Icon 1"
                            width="100%"
                            height="100%"
                            className="w-auto opacity-100"
                          />
                        </div>
                        <h4 className="my-2 text-xs font-semibold text-gray-700 underline md:text-sm decoration-2 decoration-primary text-start">
                          {item.title}
                        </h4>
                        <p className="text-xs text-center opacity-100 md:text-sm text-grey-600 md:text-normal">
                          {item.content}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <HouseSVG />
      <ListHouse />
      <section
        id="standard"
        className="p-0 text-gray-600 bg-white section md:p-4"
      >
        <div className="container relative mx-auto">
          <div className="p-6 text-start">
            <h2 className="font-bold text-primary text-md">
              PREMIUM TO DLA NAS
            </h2>
            <h3 className="text-3xl font-light ">Standard</h3>
          </div>
          <p className="p-6 pt-0 md:px-6 md:py-1 md:pt-0">
            Osiedle Słoneczne w Pobiedziskach to wyjątkowa inwestycja, która
            definiuje{" "}
            <span className="font-semibold text-primary">
              nowe standardy w branży mieszkaniowej
            </span>
            .
          </p>
          <p className="p-6 pt-0 md:px-6 md:py-1 md:pt-0">
            Nasza firma skupia się na dostarczaniu domów, które harmonijnie
            łączą innowacyjne rozwiązania z zrównoważonym podejściem do
            budownictwa.{" "}
          </p>
          <p className="p-6 pt-0 md:px-6 md:py-1 md:pb-2">
            Każdy dom w naszym osiedlu to połączenie najwyższej{" "}
            <span className="font-semibold text-primary">
              jakości materiałów, energooszczędności oraz starannie przemyślanej
              przestrzeni
            </span>
            , tworzące doskonałe warunki do życia. Jeśli szukasz wyjątkowego
            miejsca, gdzie premium staje się standardem, Osiedle Słoneczne to
            idealna propozycja dla Ciebie.
          </p>
          <div className="grid grid-cols-1 px-3 md:gap-4 md:pt-6 md:grid-cols-3">
            {standard
              .slice(0, isExpanded ? standard.length : 6)
              .map((item, index) => (
                <div
                  className="flex flex-row items-start justify-start p-2 bg-white rounded-md md:flex-row"
                  key={index}
                >
                  <div className="flex w-20 px-4">
                    <img
                      src={item.foto}
                      alt="Icon 1"
                      width={60}
                      height={60}
                      className="w-auto opacity-75"
                    />
                  </div>
                  <div className="flex-1">
                    <h4 className="mb-1 text-sm font-bold text-primary">
                      {item.nazwa}
                    </h4>
                    <p className="mb-2 text-xs text-gray-500">{item.opis}</p>
                  </div>
                  {index === 4 && !isExpanded && (
                    <div className="absolute bottom-0 left-0 right-0 p-3 text-center text-gray-700 h-36 bg-gradient-to-t from-white to-transparent">
                      <button
                        onClick={toggleExpanded}
                        className="absolute bottom-0 left-0 right-0 p-5 font-bold text-stone-500"
                      >
                        <div className="flex flex-row items-center justify-center">
                          Pokaż więcej
                          <IconSquareRoundedChevronsDown
                            size={24}
                            className="ms-2"
                          />
                        </div>
                      </button>
                    </div>
                  )}
                </div>
              ))}
          </div>
        </div>
      </section>
      <AboutInvest />
      <OZE oze={oze} />
      <section
        id="lokalizacja"
        className="w-full p-0 text-gray-600 bg-stone-100 md:p-0 "
      >
        <div className="container relative mx-auto">
          <div className="p-6 md:pt-12 text-start">
            <h2 className="font-bold text-primary text-md">LOKALIZACJA</h2>
            <h3 className="text-3xl font-light ">Pobiedziska, ul. Słoneczna</h3>
            <div className="flex flex-row mt-6 text-sm font-normal">
              <p className="mb-4 leading-6">
                Osiedle Słoneczne w Pobiedziskach usytułowane jest w cichej i
                spokojnej okolicy, w otoczeniu zieleni i przyrody. Jednocześnie
                zapewnia doskonały dostęp do infrastruktury miejskiej, co
                sprawia, że jest to{" "}
                <span className="font-bold text-primary">
                  idealne miejsce do życia dla rodzin z dziećmi
                </span>
                . W pobliżu znajdują się{" "}
                <span className="font-bold text-primary">
                  sklepy, szkoły, przedszkola, a także przystanki autobusowe i
                  dworzec PKP
                </span>
                . W odległości zaledwie 950 m od osiedla znajduje się{" "}
                <span className="font-bold text-primary">
                  jezioro Biezdruchowo
                </span>
                , które jest idealnym miejscem do wypoczynku i rekreacji. Warto
                również wspomnieć, że Pobiedziska są położone zaledwie 25 km od
                Poznania, co sprawia, że jest to idealne miejsce dla osób, które
                chcą żyć w zaciszu, a jednocześnie mieć łatwy dostęp do
                wszystkich atrakcji miasta.
              </p>
            </div>
            <div className="grid grid-cols-1 gap-2 px-3 mt-6 md:pb-6 md:grid-cols-3">
              <div className="flex flex-row">
                <div className="">
                  <img
                    src="/images/icon/maps/destination.png"
                    alt="destination"
                    width={52}
                    height={52}
                  />
                </div>
                <div className="ms-5">
                  <h6 className="p-0 mb-0 text-3xl font-bold">25 km </h6>
                  <h5 className="p-0 -mt-1 text-md">do centrum Poznania</h5>
                </div>
              </div>
              <div className="flex flex-row ">
                <div className="">
                  <img
                    src="/images/icon/maps/destination.png"
                    alt="destination"
                    width={52}
                    height={52}
                  />
                </div>
                <div className="ms-5">
                  <h6 className="p-0 mb-0 text-3xl font-bold">20 km </h6>
                  <h5 className="p-0 -mt-1 text-md">do centrum Gniezna</h5>
                </div>
              </div>
              <div className="flex flex-row">
                <div className="">
                  <img
                    src="/images/icon/maps/destination.png"
                    alt="destination"
                    width={52}
                    height={52}
                  />
                </div>
                <div className="ms-5">
                  <h6 className="p-0 mb-0 text-3xl font-bold text-gray-600">
                    10 km{" "}
                  </h6>
                  <h5 className="p-0 -mt-1 text-md">do Kostrzyna Wlkp.</h5>
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 gap-2 px-3 md:grid-cols-4"></div>
        </div>
        <div className="h-[600px]">
          <APIProvider apiKey={"AIzaSyDlJ2h0a3IpyzV_pZ4Al0tW6mDrMBvFLa8"}>
            <Map
              center={{ lat: 52.48831731942848, lng: 17.2963160276413 }}
              zoom={14}
              gestureHandling={"greedy"}
              disableDefaultUI={true}
              styles={mapStyle}
            >
              {marker.map((item, index) => (
                <Marker
                  key={index}
                  onClick={handleClick}
                  position={{ lat: item.lat, lng: item.lng }}
                  icon={{ url: item.icon }}
                >
                  {selectedMarker && (
                    <InfoWindow onCloseClick={handleCloseClick}>
                      <div className="my-info-window">
                        <h1>{item.title}</h1>
                        {item.content}
                      </div>
                    </InfoWindow>
                  )}
                </Marker>
              ))}
            </Map>
          </APIProvider>
        </div>
      </section>
      <Fotter />
    </main>
  );
}
